<template>
  <b-container class="my-4">
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card v-if="!loading">
          <b-container>
            <b-row>
              <b-col>
                <p><b-link :to="{ name: 'ImpactExperts' }">all experts</b-link></p>
                <h2>
                  <b-button class="mr-2" v-if="permission.edit" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: item.id, model: 'impactexpert' }}">Edit</b-button>
                  {{item.name}}
                </h2>
                <div class="mb-2">
                  {{item.title}} | {{item.role}} | {{item.email}} | {{item.phone}} | {{item.country}}
                </div>
                <div v-for="initiative in item.impactinitiatives" :key="'initiative'+initiative.id" class="my-4">
                  <h4><router-link :to="{ name: 'ImpactInitiative', params: { id: initiative.id }}"><h4 class="card-title">{{initiative.name}}</h4></router-link></h4>
                  <div>{{initiative.country}} | {{initiative.impactstatus.name}}</div>
                  <div>{{initiative.headline}}</div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'

export default {
  name: 'Expert',
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'expert', action: 'open expert', model: 'impactexpert', model_id: this.$route.params.id })
    this.permission.edit = ac.can(this.user.acgroups).updateAny('impactexpert').granted
    this.load()
  },
  data () {
    return {
      loading: true,
      item: null,
      permission: {
        edit: false
      }
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = `/impact/expert/${this.$route.params.id}`
        this.item = await this.$Amplify.API.get(apiName, path)
        this.loading = false
      } catch (e) {
        this.$logger.warn('/risk/:id error ', e)
      }
    }
  }
}
</script>

<style scoped>

</style>
